import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import SignatureCanvas from "react-signature-canvas";

function InstantSignature() {
  const mainStyle = {
    _instantSig: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.8)",
      display: "grid",
      placeContent: "center",
      border: "1px solid #ccc",
      padding: "20px",
      zIndex: 1000,
    },
    _canvas: {
      background: "white",
      padding: "2em",
      borderRadius: "8px",
      display: "grid",
      gap: "1em",
    },
    _buttons: {
      display: "flex",
      height: "3em",
      width: "auto",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: "2em",
    },
    _submitButton: {
      background: "rgb(36, 99, 174)",
      color: "white",
      padding: "4px 16px",
      borderRadius: "4px",
      cursor: "pointer",
    }
  };

  const { setOpenSignatureModal, setSignature, signature } = useAuth();
  const signatureCanvas = useRef(null);
  const [strokes, setStrokes] = useState([]);

  const handleEndStroke = () => {
    const currentDataURL = signatureCanvas.current.toDataURL();
    setStrokes((prevStrokes) => [...prevStrokes, currentDataURL]); // Save the canvas state after each stroke
  };
  //   const sigCanvas = useRef(strokes);

  // Function to clear the signature pad
  const clearSignature = () => {
    signatureCanvas.current.clear();
    setStrokes([]); // Clear all tracked strokes
  };

  // Undo the last stroke
  const undoLastStroke = () => {
    if (strokes.length > 0) {
      const newStrokes = strokes.slice(0, -1); // Remove the last stroke
      setStrokes(newStrokes);

      // Clear the canvas and redraw from saved strokes
      signatureCanvas.current.clear();
      newStrokes.forEach((stroke) => {
        const img = new Image();
        img.src = stroke;
        img.onload = () => {
          signatureCanvas.current
            .getCanvas()
            .getContext("2d")
            .drawImage(img, 0, 0);
        };
      });
    }
  };

  // Function to complete the signature and save it
  const handleComplete = () => {
    setSignature(signatureCanvas.current.toDataURL()); // Save the signature state
    // setSignature(strokes); // Save the signature state
    clearSignature(); // Clear the signature pad
    setOpenSignatureModal(false); // Close the signature modal when the signature is completed
  };

  useEffect(() => {
    console.log(signature)
  },[signature])

  return (
    <div className="instantSignature" style={mainStyle._instantSig}>
      <div className="canvas" style={mainStyle._canvas}>
        <h2 style={{fontSize: "larger"}}>Draw your Signature</h2>
        <SignatureCanvas
          ref={signatureCanvas}
          penColor="black"
          onEnd={handleEndStroke} // Triggered when the user completes a stroke
          canvasProps={{
            style: {
              border: "1px solid black",
              cursor: "crosshair",
            },
            className: "signatureCanvas",
            height: "180px",
            touchAction: "none",
          }}
        />
        <div className="buttons" style={mainStyle._buttons}>
          <a onClick={clearSignature}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="36px"
              width="36px"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </svg>
          </a>
          <a onClick={undoLastStroke} disabled={strokes.length === 0}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="36px"
              width="36px"
            >
              <path d="M262.3 179.6c-57.9 0-110.3 21.6-150.7 56.8L32 160v192h197.5l-79.1-76.8c30.4-25.3 69-41.1 111.8-41.1 77.3 0 143.1 48.2 166 117.8l51.8-14.8c-30.4-91.4-116.2-157.5-217.7-157.5z" />
            </svg>
          </a>
          <a onClick={handleComplete} disabled={strokes.length === 0} style={mainStyle._submitButton}>
            Done
          </a>
        </div>
      </div>
    </div>
  );
}

export default InstantSignature;
