import { createContext, useContext, useMemo, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { appSettings, backendApis, IS_HR } from "../config";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useLocalStorage } from "../utils/hooks";
// import { useStoreActions, useStoreState } from "easy-peasy";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [authVendor, setAuthVendor] = useLocalStorage("vendor", null);
  const [vendorApi, setVendorApi] = useLocalStorage("vendorAPI", null);
  const [isExpired, setIsExpired] = useState(null);
  const [deviceID, setDeviceID] = useState("")
  const [customer, setCustomer] = useLocalStorage("customer", null);
  const [signature, setSignature] = useState(null);
  const [openSignatureModal, setOpenSignatureModal] = useState(false);
  const navigate = useNavigate();


 

  const login = async (data, location = null, accountExpired) => {
    setUser(data);

    if (location == null) {
      return;
    }

    if (location.state?.from) {
      navigate(location.state.from, { replace: true });
    } else {
      if (accountExpired) {
        setIsExpired(accountExpired);
        return navigate("/payment-and-billing", { replace: true });
      }

      navigate("/dashboard", { replace: true });
    }
  };

  const VendorLogin = async (data, location) => {
    setAuthVendor(data);
    setVendorApi(data.vendorApi);
    setIsExpired(data.isExpired);
    if (location.state?.from) {
      navigate(location.state.from, { replace: true });
    } else {
      navigate("/vendor-user/Request-For-Quote", { replace: true });
    }
  };

  const loginCustomer = async (data, location) => {
    setCustomer(data);
    if (location.state?.from) {
      navigate(location.state.from, { replace: true });
    } else {
      // navigate("/store", { replace: true });
    }
  };

  const logout = () => {
    setUser(null);
    const cookies = new Cookies();
    if (IS_HR) {
      // const cookies = new Cookies();
      // cookies.remove("token");
      // cookies.remove("company_id");
      // cookies.remove("user");
      // cookies.remove("isSuperAdmin");
      // cookies.remove("isAdmin");
      // cookies.remove("userId");
      // cookies.remove("isLoggedIn");
      // cookies.remove("isFirstTime");
      // cookies.remove("branch_id");
      cookies.remove("token", { path: "/" });
      cookies.remove("company_id", { path: "/" });
      cookies.remove("user", { path: "/" });
      cookies.remove("isSuperAdmin", { path: "/" });
      cookies.remove("isAdmin", { path: "/" });
      cookies.remove("userId", { path: "/" });
      cookies.remove("isLoggedIn", { path: "/" });
      cookies.remove("isFirstTime", { path: "/" });
      cookies.remove("branch_id", { path: "/" });
    }

    cookies.remove("x-access-token", {
      path: "/",
      // domain: ".invexonline.com",
    });
    navigate("/login", { replace: true });
  };

  const logoutVendor = () => {
    setAuthVendor(null);
    navigate("/vendor-login", { replace: true });
  };

  const setDepartmentAndJobGrade = ({ Department, jobGrade, AccessLavel }) => {
    setUser({
      ...user,
      Department,
      jobGrade,
      AccessLavel,
    });
  };
  
  const getFingerprint = async () => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    return result.visitorId; // This is a unique identifier for the device
  };

  useEffect(() => {

    getFingerprint().then((deviceId) => {
      setDeviceID(deviceId);
    });
  },[])

  const logoutCustomer = async () => {
    localStorage.removeItem("customer");
  };

  const value = useMemo(() => {
    let backendApi = backendApis.find((el) => el.name === user?.company);
    // console.log("backendApi", backendApi);

    if (!backendApi) {
      console.log("vendorApi", vendorApi);
      backendApi = backendApis.find((el) => el.url === vendorApi);
      console.log("backendApi", backendApi);
    }
    const isCement = ["Dangote Cement", "Bua Cement"].includes(user?.company);

    return {
      user,
      login,
      logout,
      authVendor,
      setAuthVendor,
      VendorLogin,
      logoutVendor,
      isExpired,
      vendorApi,
      loginCustomer,
      logoutCustomer,
      customer,
      customerToken: customer?.customerToken,
      setUser,
      deviceID,
      setDeviceID,
      token: user?.token,
      backendUrl: backendApi ? backendApi?.url : "",
      brachData: backendApi ? backendApi : "",
      isCement,
      isTransportAndLogistics: user?.company === "Transport and Logistics",
      isOilAndGas: user?.company === "Oil and Gas",
      isCrystalWireIndustries: user?.company === "CrystalWire Industries",
      isGIGCHoldings: user?.company === "GIGC Holdings",
      isIronRod:
        ["Iron Rods", "Iron Rods - Akure"].includes(user?.company) ||
        (appSettings.isBatchStandard && !isCement),
      // isIronRod: true,
      setDepartmentAndJobGrade,
      customerBackendUrl: backendApis.find((el) => el.isStore)?.url,
      openSignatureModal,
      setOpenSignatureModal,
      signature,
      setSignature,
    };
  }, [user, user?.company, authVendor, vendorApi, user?.company, customer, openSignatureModal, signature]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
