import "./../assets/scss/pageheader.scss";
import { BackIcon, InvoiceBackIcon, PageHeaderListIcon } from "./Icons";
import photoUrl from "./../assets/images/profile.png";
import { Link, useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";

export default function InvoicePageHeader({
  name = "...",
  description = "...",
  icon = <PageHeaderListIcon />,
  DescriptionComponent,
  NonShowBack,
}) {
  let navigate = useNavigate();

  console.log("====================================");
  console.log(name);
  console.log("====================================");

  return (
    <div className="page-header d-flex justify-content-between align-items-center px-3 py-4 bg-white">
      <div className="title-area d-flex align-items-center">
        {!NonShowBack && (
          <button onClick={() => navigate(-1)} className="btn back-btn">
            <div className="invoice-icon text-primary">
              {" "}
              <InvoiceBackIcon />
            </div>
          </button>
        )}

        <div className="d-flex align-items-center">
          <div className="icon text-primary">{icon}</div>
          <div>
            <h2>{name}</h2>
            <p>{description}</p>
            {DescriptionComponent ? DescriptionComponent : null}
          </div>
        </div>
      </div>

      {/* <div>
        <button className="btn user-profile">
          <div className="avatar">
            <Image src={photoUrl} />
          </div>
          <div className="user-info me-1">
            <span>Admin 01</span>
            <span>admin_username</span>
          </div>

          <ChevronDownIcon />
        </button>
  </div> */}
    </div>
  );
}
