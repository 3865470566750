import { useFormik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import { Modal, Form, Nav, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import * as yup from "yup";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import EyeOutline from "mdi-react/EyeOutlineIcon";
import "../assets/scss/fileModal.scss";
import { useBackendUrl, useEffectOnce } from "../utils/hooks";
import Avatar from "./utils/Avatar";
import { useAuth } from "../hooks/useAuth";
import Cookie from "universal-cookie";
import UserDevices from "./modals/UserDevices";
import SignaturePad from "./Dashboard/SignaturePad";

export default function UpdateProfileModal(props) {
  const cookies = new Cookie();
  const { user: authUser, setUser } = useAuth();
  const [activeKey, setActiveKey] = useState("account");
  const [showText, setShowText] = useState({
    password: false,
    confirmpassword: false,
    oldPassword: false,
  });
  const [showImage, setShowImage] = useState(false);

  const backendUrl = useBackendUrl();

  // console.log(authUser);
  // const token = useToken();

  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordLoading, setIsPasswordLoading] = useState(false);
  const [newsignaturePics, setnewSignaturePics] = useState("");
  const [switchLoading, setSwitchLoading] = useState(false);
  const [twoFAState, setTwoFAState] = useState(() => {
    return JSON.parse(localStorage.getItem("user")).isTwoFAActivated;
  });
  const [newDeviceState, setNewDeviceState] = useState(
    authUser.isNewDeviceActivated
  );
  const [activeSubNav, setActiveSubNav] = useState("password");
  const [autoLogoutTimoutinputValue, setAutoLogoutTimoutinputValue] = useState(
    authUser.autoLogoutTimeout || 60
  );
  const [autoLogoutTimoutinputError, setAutoLogoutTimoutinputError] = useState(
    null
  );
  const [strokes, setStrokes] = useState([]); // Array to store each stroke of the signature
  const [signature, setSignature] = useState(null);
  const signatureCanvas = useRef(null);


  // effect to always reset activeSubNav to password then it unmounts
  useEffect(() => {
    setActiveSubNav("password");
    return () => {
      setActiveSubNav("password");
    };
  }, [activeKey]);

  const updateAccount = async (values) => {
    try {
      setIsLoading(true);
      let response = await fetch(`${backendUrl}/api/users/update-account`, {
        method: "POST",
        body: values,
      });

      if (!response.ok) {
        response = await response.json();
        if (response.errors) formik.setErrors(response.errors);
        toast.error(response.message);
      } else {
        response = await response.json();
        setUser({ ...authUser, ...response.data.user });
        toast.success("Success");
      }
    } catch (err) {
      toast.error("Unable to submit");
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      Name: "",
      email: "",
      profilePics: "",
      Staff_ID: authUser.Staff_ID,
    },
    validationSchema: yup.object().shape({
      Name: yup.string().required(),
      email: yup.string().required(),
    }),
    onSubmit: (values) => {
      const { profilePics, ...rest } = values;

      const formData = new FormData();
      for (const [key, value] of Object.entries(rest)) {
        formData.append(key, value);
      }

      if (profilePics) {
        formData.append(
          "profilePics",
          profilePics,
          `${rest.Name}${rest.Staff_ID}_profilePics`
        );
      }

      updateAccount(formData);
    },
  });

  const OnChangeInput = (e) => {
    const { files } = e.target;
    formik.setFieldValue("profilePics", files[0]);
  };

  // ---------------------------

  //-----------------------------

  useEffectOnce(() => {
    setUp();
  });

  const setUp = () => {
    formik.setFieldValue("Name", authUser.Name);
    formik.setFieldValue("email", authUser.email);
  };

  // ----------------------------

  const formikPassword = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      Staff_ID: authUser.Staff_ID,
      username: authUser.username,
    },
    validationSchema: yup.object().shape({
      oldPassword: yup.string().required(),
      newPassword: yup.string().required(),
      confirmNewPassword: yup.string().required(),
    }),
    onSubmit: async (values) => {
      const {
        Staff_ID,
        oldPassword,
        username,
        confirmNewPassword,
        newPassword,
      } = values;

      if (confirmNewPassword !== newPassword) {
        formikPassword.setFieldError(
          "confirmNewPassword",
          "Mis Match Password"
        );

        formikPassword.setFieldError("newPassword", "Mis Match Password");
        return;
      }

      const data = {
        oldPassword,
        newPassword,
        Staff_ID,
        username,
        fextoken: cookies.get("fextoken"),
      };

      try {
        setIsPasswordLoading(true);
        let res = await fetch(`${backendUrl}/api/users/change-password`, {
          method: "POST",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          body: JSON.stringify(data),
        });

        setIsPasswordLoading(false);
        if (!res.ok) {
          res = await res.json();
          if (res.errors) formikPassword.setErrors(res.errors);
          toast.error(res.message);
        } else {
          toast.success("Password Updated Successfully");
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const sendPics = async () => {
    if (newsignaturePics) {
      const formData = new FormData();
      formData.append(
        "signatures",
        newsignaturePics,
        `${authUser.Staff_ID}_signatures`
      );
      formData.append("Staff_ID", authUser.Staff_ID);
      updateAccount(formData);
    }
    if (strokes.length) {
      try {
        setIsLoading(true);
        const signature = signatureCanvas.current.toDataURL();
        const response = await fetch(
          `${backendUrl}/api/users/save-canvas-signature`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              Staff_ID: authUser.Staff_ID,
              signature: signature,
            }),
          }
        );
        if (!response.ok)
          throw new Error("Something went wrong, check your connection");
        const result = await response.json();
        if (!result.success) {
          throw new Error(result.message);
        } else {
          toast.success("Signature saved successfully");
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      // saveSignature()
      toast.error("Change Signature Before You Send");
    }
  };

  async function createImageBlob(url) {
    try {
      const response = await fetch(
        `${backendUrl}/api/users/signature/${authUser.Staff_ID}`
      );

      const data = await response.blob();
      if (Boolean(data?.size)) {
        setShowImage(false);
      } else {
        setShowImage(true);
      }
      return;
    } catch (error) {
      return null;
    }
  }

  async function getSignatureCanvas() {
    try {
      const response = await fetch(
        `${backendUrl}/api/users/canvas-signature/${authUser.Staff_ID}`
      );
      const data = await response.json();
      if (data.success) {
        setSignature(data.signature);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      setSignature(null);
    }
  }

  useEffect(() => {
    createImageBlob();
    getSignatureCanvas();
  }, [showImage, authUser?.Staff_ID]);

  // Two factor authentication setup
  const handleTwoFactorClick = async (e) => {
    const action = e.target.attributes.id.value;

    let actionState;
    let actionResponse;

    if (action === "twoFA") {
      actionResponse = `Two-factor authentication ${
        twoFAState ? "deactivated" : "activated"
      }`;
      actionState = setTwoFAState;
    } else if (action === "newDevice") {
      actionResponse = `New device detection ${
        newDeviceState ? "deactivated" : "activated"
      }`;
      actionState = setNewDeviceState;
    } else {
      actionResponse = "Invalid action";
    }
    try {
      setSwitchLoading(true);
      const data = {
        Staff_ID: authUser.Staff_ID,
        email: authUser.email,
        action,
        value: Number(e.target.checked),
      };
      let response = await fetch(
        `${backendUrl}/api/users/update-two-factor-auth`,
        {
          method: "POST",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          // passing the checkbox state as a number
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        toast.error("something went wrong, check your connection");
      } else {
        response = await response.json();
        if (!response.success) throw new Error(response.message);
        if (response.success) {
          actionState(Boolean(response.data));
          toast.success(actionResponse);
          const localUser = JSON.parse(localStorage.getItem("user"));
          localUser.isTwoFAActivated = Boolean(response.data);
          localStorage.setItem("user", JSON.stringify(localUser));
        }
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setSwitchLoading(false);
    }
  };

  const handleAutoLogoutTimeout = async () => {
    try {
      // setIsLoading(true)
      // use yup to validate the input, ensure it's number and is in range of 5 to 60
      const validationSchema = yup.object({
        value: yup
          .number()
          .min(5)
          .max(60),
      });
      validationSchema
        .isValid({ value: autoLogoutTimoutinputValue })
        .then(async (valid) => {
          if (!valid) {
            setAutoLogoutTimoutinputError(
              "Please enter a number between 5 and 60"
            );
            return;
          }
          const data = {
            email: authUser.email,
            Staff_ID: authUser.Staff_ID,
            value: autoLogoutTimoutinputValue,
          };
          let response = await fetch(
            `${backendUrl}/api/users/auto-logout-timeout`,
            {
              method: "POST",
              headers: {
                Accept: "Application/json",
                "Content-Type": "Application/json",
              },
              // passing the checkbox state as a number
              body: JSON.stringify(data),
            }
          );
          if (!response.ok) {
            setAutoLogoutTimoutinputError("Connection Error");
            return;
          }
          response = await response.json();
          if (!response.success) {
            setAutoLogoutTimoutinputError(response.message);
            return;
          }
          setUser({ ...authUser, autoLogoutTimeout: response.data });
          setIsLoading(false);
          toast.success("Auto-logout timeout updated successfully");
        });
      if (autoLogoutTimoutinputError)
        throw new Error(autoLogoutTimoutinputError);
    } catch (err) {
      setIsLoading(false);
      toast.error(err.message);
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={props.show}
        onHide={props.onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Account Editor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="row">
          <div className="col px-4">
            <Nav
              variant="tabs"
              defaultActiveKey="account"
              onSelect={(key) => setActiveKey(key)}
              className="mb-3"
            >
              <Nav.Item>
                <Nav.Link eventKey="account">Account</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="security">Security</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="signature">Signature</Nav.Link>
              </Nav.Item>
            </Nav>
            {activeKey === "account" && (
              <div className="container row">
                <div className="col-md-4 text-center p-3">
                  <div className="d-flex justify-content-center">
                    <Avatar
                      name={authUser?.Name}
                      company={authUser?.company}
                      style={{ width: "10rem", height: "10rem" }}
                      onFileUpload={OnChangeInput}
                      image={formik.values.profilePics}
                      userId={authUser?.Staff_ID}
                    />
                  </div>

                  <div className="mt-2 fw-bold">Profile picture</div>
                </div>
                <Form className="col" noValidate onSubmit={formik.handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your name"
                      name="username"
                      value={authUser.username}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your name"
                      name="Name"
                      value={formik.values.Name}
                      onChange={formik.handleChange}
                      isInvalid={formik.touched.Name && !!formik.errors.Name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.Name}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      isInvalid={formik.touched.email && !!formik.errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button
                    disabled={isLoading}
                    variant="primary"
                    className="my-3"
                    // onClick={formik.handleSubmit}
                    type="submit"
                  >
                    {isLoading ? "Please wait…" : "Save Changes"}
                  </Button>
                </Form>
              </div>
            )}
            {activeKey === "security" && (
              <div>
                <Nav
                  variant="tabs"
                  defaultActiveKey="password"
                  onSelect={(key) => setActiveSubNav(key)}
                  className="mb-3"
                >
                  <Nav.Item>
                    <Nav.Link eventKey="password">Password</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="session">Session</Nav.Link>
                  </Nav.Item>
                </Nav>
                {activeSubNav === "password" && (
                  <Form
                    className="col"
                    noValidate
                    onSubmit={formikPassword.handleSubmit}
                  >
                    <Form.Group className="mb-3">
                      <Form.Label>Old Password</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type={showText.oldPassword ? "text" : "password"}
                          name="oldPassword"
                          placeholder="••••••••"
                          value={formikPassword.values.oldPassword}
                          onChange={formikPassword.handleChange}
                          isInvalid={
                            formikPassword.touched.oldPassword &&
                            !!formikPassword.errors.oldPassword
                          }
                        />
                        {showText.oldPassword ? (
                          <InputGroup.Text
                            onClick={() =>
                              setShowText({ ...showText, oldPassword: false })
                            }
                          >
                            <EyeOutline />
                          </InputGroup.Text>
                        ) : (
                          <InputGroup.Text
                            onClick={() =>
                              setShowText({ ...showText, oldPassword: true })
                            }
                          >
                            <EyeOffOutline />
                          </InputGroup.Text>
                        )}
                      </InputGroup>

                      {formikPassword.errors.oldPassword && (
                        <span className="text-danger">
                          {formikPassword.errors.oldPassword}
                        </span>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>New Password</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type={showText.password ? "text" : "password"}
                          name="newPassword"
                          placeholder="••••••••"
                          value={formikPassword.values.newPassword}
                          onChange={formikPassword.handleChange}
                          isInvalid={
                            formikPassword.touched.newPassword &&
                            !!formikPassword.errors.newPassword
                          }
                        />
                        {showText.password ? (
                          <InputGroup.Text
                            onClick={() =>
                              setShowText({ ...showText, password: false })
                            }
                          >
                            <EyeOutline />
                          </InputGroup.Text>
                        ) : (
                          <InputGroup.Text
                            onClick={() =>
                              setShowText({ ...showText, password: true })
                            }
                          >
                            <EyeOffOutline />
                          </InputGroup.Text>
                        )}
                      </InputGroup>

                      {formikPassword.errors.newPassword && (
                        <span className="text-danger">
                          {formikPassword.errors.newPassword}
                        </span>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Confirm New Password</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type={showText.confirmpassword ? "text" : "password"}
                          placeholder="••••••••"
                          name="confirmNewPassword"
                          value={formikPassword.values.confirmNewPassword}
                          onChange={formikPassword.handleChange}
                          isInvalid={
                            formikPassword.touched.confirmNewPassword &&
                            !!formikPassword.errors.confirmNewPassword
                          }
                        />
                        {showText.confirmpassword ? (
                          <InputGroup.Text
                            onClick={() =>
                              setShowText({
                                ...showText,
                                confirmpassword: false,
                              })
                            }
                          >
                            <EyeOutline />
                          </InputGroup.Text>
                        ) : (
                          <InputGroup.Text
                            onClick={() =>
                              setShowText({
                                ...showText,
                                confirmpassword: true,
                              })
                            }
                          >
                            <EyeOffOutline />
                          </InputGroup.Text>
                        )}
                      </InputGroup>
                      {formikPassword.errors.confirmNewPassword && (
                        <span className="text-danger">
                          {formikPassword.errors.confirmNewPassword}
                        </span>
                      )}
                    </Form.Group>
                    <Form.Check
                      type="switch"
                      id="twoFA"
                      label="Two-factor Authentication"
                      defaultChecked={twoFAState}
                      onChange={handleTwoFactorClick}
                      disabled={switchLoading}
                    />
                    <br />
                    <Form.Check
                      type="switch"
                      id="newDevice"
                      label="New Device Detection"
                      defaultChecked={newDeviceState}
                      onChange={handleTwoFactorClick}
                      disabled={switchLoading}
                    />
                    <br />
                    <Button
                      disabled={isPasswordLoading}
                      variant="primary"
                      className="my-3"
                      type="submit"
                    >
                      {isPasswordLoading ? "Please wait…" : "Change password"}
                    </Button>
                  </Form>
                )}
                {activeSubNav === "session" && (
                  <div className="col ">
                    <UserDevices />
                    <br />
                    <div className="col autoLogoutSlider">
                      <p>Auto logout timeout</p>
                      <IntegerStep
                        inputValue={autoLogoutTimoutinputValue}
                        setInputValue={setAutoLogoutTimoutinputValue}
                      />
                      <Button
                        disabled={isLoading}
                        variant="primary"
                        className="my-3"
                        onClick={handleAutoLogoutTimeout}
                        // onClick={formik.handleSubmit}
                        type="submit"
                      >
                        {isLoading ? "Please wait…" : "Save Changes"}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}

            {activeKey === "signature" && (
              <div className="my-3 profile-signature  ">
                <p>Upload a Signature or draw your signature</p>
                <div className="signature-choice-container">
                  <label className="p-cursor">
                    {!newsignaturePics ? (
                      <div className=" position-relative">
                        <img
                          style={{
                            width: "15rem",
                            height: "15rem",
                          }}
                          alt=""
                          src={`${backendUrl}/api/users/signature/${
                            authUser.Staff_ID
                          } `}
                        />

                        {showImage && (
                          <span
                            className=" position-absolute text-nowrap"
                            style={{ top: "50%", left: "20%" }}
                          >
                            Upload Signature
                          </span>
                        )}
                      </div>
                    ) : (
                      <img
                        style={{
                          width: "12rem",
                          height: "12rem",
                        }}
                        alt=""
                        src={URL.createObjectURL(newsignaturePics)}
                      />
                    )}
                    <input
                      type="file"
                      onChange={(e) =>
                        setnewSignaturePics(() => e.target.files[0])
                      }
                      className="d-none"
                    />
                  </label>

                  <SignaturePad
                    setSignature={setSignature}
                    signatureCanvas={signatureCanvas}
                    signature={signature}
                    strokes={strokes}
                    setStrokes={setStrokes}
                  />
                </div>
                <div className="mt-2 fw-bold">Signature</div>
                <div className="mt-5 d-flex">
                  <button
                    disabled={isLoading}
                    onClick={sendPics}
                    className="btn btn-primary"
                  >
                    {isLoading ? "Please wait…" : "Save Signature"}
                  </button>

                  <button
                    onClick={() => setnewSignaturePics("")}
                    className="btn btn-secondary mx-4"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            disabled={isLoading}
            variant="primary"
            className=""
            type="submit"
          >
            {isLoading ? "Please wait…" : "Continue"}
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

const IntegerStep = ({ inputValue, setInputValue }) => {
  const MINSLIDER = 5;
  const MAXSLIDER = 60;
  const [sliderValue, setSliderValue] = useState(
    Math.floor(inputValue * 1.81) + 5
  );
  const onChange = (newValue) => {
    console.log(newValue);
    setInputValue(Math.floor(newValue / 1.81) + 5);
    setSliderValue(newValue);
  };

  return (
    <div className="timeout-input-group">
      <Form.Range
        onChange={(e) => onChange(Number(e.target.value))}
        value={sliderValue}
      />
      <InputGroup className="mb-3">
        <Form.Control
          placeholder="Auto logout timeout"
          aria-label="Auto logout timeout"
          aria-describedby="basic-addon2"
          value={inputValue}
        />
        <InputGroup.Text id="basic-addon2">mins</InputGroup.Text>
      </InputGroup>
    </div>
  );
};
