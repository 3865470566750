import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { Bar, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useQuery, useQueryClient } from "react-query";
import { useEffect } from "react";
import { services } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import { useState } from "react";
import useDebounce, {
  useChosenBackendUrl,
  useCurrencies,
  useIsGovernmentInvexERP,
} from "../../utils/hooks";
import queryString from "query-string";
import CachedIcon from "mdi-react/CachedIcon";
import Expenses from "../Overviews/Expenses";
import Revenue from "../Overviews/Revenue";
import { isEmpty } from "lodash";
import { format } from "date-fns";
import Select from "react-select";
import { analyticsOptions } from "../../utils/helpers";

import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import "../../assets/scss/customInvoiceWrapper.scss";
import "../../assets/scss/dashboard.scss";

import {
  AmountpaidLegendIcon,
  AmountRemainingLegendIcon,
  InvoiceAmountPaid,
  OverdueInvoicePieChartIcon,
  PaidInvoicePieChartIcon,
  UnpaidInvoicePieChartIcon,
  UpcomingInvoicePieChartIcon,
} from "../Icons";
import { ArcElement } from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

export default function InvoiceAnalysisPieChart() {
  const {
    deploymentCurrencies: currenciesOptions,
    prevailingCurrency,
  } = useCurrencies();

  const initialFilterParams = {
    by: "year",
    currency: prevailingCurrency,
    year: new Date().getFullYear(),
  };
  const isGovernmentInvexERP = useIsGovernmentInvexERP();
  const backendUrl = useChosenBackendUrl();
  const [show, setShow] = useState(false);
  const [queryParams, setQueryParams] = useState({
    ...initialFilterParams,
  });
  const debouncedqueryParams = useDebounce(queryParams, 800);

  const getRevenue = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/dashboard/get-revenue?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { error, data, isSuccess, refetch, isFetching } = useQuery(
    ["GET_REVENUE", debouncedqueryParams, backendUrl],
    () => getRevenue(debouncedqueryParams),
    {
      // enabled: false,
      // cacheTime: 0,
      onSuccess: (data) => {
        // queryClient.removeQueries("LOGIN");
      },
    }
  );

  useEffect(() => {
    console.log(error);
  }, [error]);
  const dat = {
    labels: ["Green", "Blue", "Yellow", "Red"], // Labels for the chart
    datasets: [
      {
        data: [40, 30, 20, 10], // Replace with your data values
        backgroundColor: [
          "#34c759", // Green
          "#007aff", // Blue
          "#ffcc00", // Yellow
          "#ff3b30", // Red
        ],
        weight: 1,
        clip: 60,
      },
    ],
  };

  return (
    <div className="custom-chart p-4">
      <header className="section-header  d-flex justify-content-between">
        <h5 className="title">
          Invoice Status
          {/* <button
            title="Refresh"
            onClick={() => refetch()}
            className="btn text-primary"
          >
            <CachedIcon />
          </button> */}
        </h5>

        <div className="position-relative z-10">
          <Select
            classNamePrefix={`form-select-analytics`}
            isSearchable={false}
            options={analyticsOptions}
            value={analyticsOptions.find((el) => el.value === queryParams.by)}
            onChange={(selected) => {
              setQueryParams({
                ...queryParams,
                by: selected?.value,
              });
            }}
          />
        </div>
      </header>

      {/* bar chart */}

      <div
        className="custom-chart-holder   mt-4 mx-auto my-auto d-flex justify-content-center align-items-center"
        style={{
          height: "20rem",
          width: "20rem",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {
          <Doughnut
            data={dat}
            options={{
              cutout: "70%",

              plugins: {
                legend: {
                  display: false, // Hide legend
                  position: "bottom",
                  labels: {
                    fontColor: "#94A3B8",
                    fontSize: 12,
                    boxHeight: 8,
                    boxWidth: 8,
                    pointStyle: "circle",
                    usePointStyle: true,
                    padding: 10,
                  },
                },
              },
            }}
          />
        }

        {/* <div className="blur" /> */}
      </div>
      <div className=" row mx-auto" style={{ maxWidth: "320px" }}>
        <div className=" col-md-6">
          <PaidInvoicePieChartIcon /> Paid Invoice
        </div>
        <div className=" col-md-6     chart-desc">
          <UpcomingInvoicePieChartIcon /> Upcoming Invoice
        </div>
      </div>
      <div className=" row mx-auto mt-3" style={{ maxWidth: "320px" }}>
        <div className=" col-md-6">
          <OverdueInvoicePieChartIcon /> Overdue Invoice
        </div>
        <div className=" col-md-6     chart-desc">
          <UnpaidInvoicePieChartIcon /> Unpaid Invoice
        </div>
      </div>
      <OverviewModal
        show={show}
        setShow={setShow}
        dateRange={
          !isEmpty(data)
            ? {
                startDate: data[0].startDate,
                endDate: data[data.length - 1].endDate,
              }
            : {}
        }
      />
    </div>
  );
}

export function OverviewModal({ show, setShow, dateRange = {} }) {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-block">
          <h2 className="h5 text-nowrap w-100">
            Total Revenue vs Expenses{" "}
            {dateRange?.startDate && dateRange?.endDate ? (
              <span>
                {dateRange.startDate
                  ? format(new Date(dateRange.startDate), "dd MMM, yyy")
                  : ""}
                {" - "}
                {dateRange.endDate
                  ? format(new Date(dateRange.endDate), "dd MMM, yyy")
                  : ""}
              </span>
            ) : null}
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row justify-content-between">
          <div className="col-md-6  pe-5">
            <Revenue dateRange={dateRange} />
          </div>
          <div className="col-md-6  ps-5">
            <Expenses dateRange={dateRange} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
