import React, { useState } from "react";
import { Card, Button, Table } from "react-bootstrap";
import "../../assets/scss/payment-and-billing.scss";
import PageHeader from "../PageHeader";
import { PaymentAndBillingIcon } from "../Icons";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useAuth } from "../../hooks/useAuth";
import currency from "currency.js";

export default function PaymentAndBilling() {
  const navigate = useNavigate();
  const { backendUrl, token } = useAuth();
  const [expiredOrRenew, setExpiredOrRenew] = useState(null);

  const fetchCompanDetails = async (url) => {
    const res = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    });

    const data = await res.json();

    const companyData = data?.company;

    // ==
    if (!companyData?.planDurationType) {
      companyData.planDurationType = "yearly";
    }
    if (!companyData.coreUsers) {
      companyData.coreUsers = companyData.Num_Users;
    }
    if (!companyData.passiveUsers) {
      companyData.passiveUsers = 0;
    }

    // ===
    return { data: companyData, pricing: data?.pricing };
  };

  const getStatus = (date) => {
    const currentDate = new Date().getTime();
    let expiringDate = new Date(date);
    expiringDate.setDate(expiringDate.getDate() - 9);

    expiringDate = new Date(expiringDate).getTime();

    let status = currentDate > expiringDate ? "Expired" : "Active";
    return status;
  };

  function getExpiringDate(date) {
    let expDate = new Date(date);

    // expDate.setDate(expDate.getDate() - 9);

    return expDate;
  }

  const { data } = useQuery(
    ["GET_COMPANY_DETAILS"],
    () => fetchCompanDetails(`${backendUrl}/api/company/details`),

    {
      enabled: true,
      onSuccess: ({ data }) => {
        getStatus(data?.ExpiryDate) === "Active"
          ? setExpiredOrRenew(false)
          : setExpiredOrRenew(true);
      },
    }
  );

  const handleRenewPlanNavigate = () => {
    if (expiredOrRenew) {
      navigate("/renew-expired-account", {
        state: { isExpired: expiredOrRenew },
      });
    } else {
      navigate("/renew-business-plan", {
        state: { isExpired: expiredOrRenew },
      });
    }
  };

  const formatDate = (date) => {
    let userDate = new Date(date);
    userDate.setDate(userDate.getDate() - 9);

    userDate = new Date(userDate).toDateString();
    return userDate;
  };

  const priceERPCoreUser =
    data?.data?.planDurationType?.toLowerCase() === "yearly"
      ? Number(data?.pricing?.coreUserPerYear) * Number(data?.data?.coreUsers)
      : Number(data?.pricing?.coreUserPeryMonth) *
        Number(data?.data?.coreUsers);

  const priceERPPassive =
    data?.data?.planDurationType?.toLowerCase() === "yearly"
      ? Number(data?.pricing?.passiveUserPerYear) *
        Number(data?.data?.passiveUsers)
      : Number(data?.pricing?.passiveUserPeryMonth) *
        Number(data?.data?.passiveUsers);

  const invexOnePrice =
    data?.data?.planDurationType?.toLowerCase() === "yearly"
      ? Number(data?.data?.Num_Users) *
        Number(data?.pricing?.invexOnePricePerYear)
      : Number(data?.pricing?.invexOnePricePerMonth) *
        Number(data?.data?.Num_Users);

  /*!SECTION
  Number(data?.data?.coreUsers) *
                                Number(data?.pricing?.pricePerCoreUser) +
                                Number(data?.data?.passiveUsers) *
                                  Number(data?.pricing?.pricePerPassiveUser)

  */

  return (
    <main className="inner-page-outlet">
      <PageHeader
        name="Payment & Billing"
        description="Renew your business plan"
        icon={<PaymentAndBillingIcon />}
      />
      <main className="">
        <div className="payment-and-billing-container">
          <div className="payment-and-billing-inner-container  mx-auto w-100 p-5 px-3">
            <Card className="">
              <Card.Body className="p-4 ">
                <div className="  d-flex justify-content-between  align-items-center my-3">
                  <h6 className="card-header-title  my-3">Business Plan</h6>
                </div>
                <Table responsive>
                  <tbody>
                    <tr className="border-none">
                      <td className="fw-bold">Status: </td>
                      <td className=" fw-bold">
                        {" "}
                        {Boolean(data?.data?.ExpiryDate) &&
                        getStatus(data?.data?.ExpiryDate) === "Active" ? (
                          <span className="text-success">Active</span>
                        ) : (
                          <span className="text-danger">Expired</span>
                        )}
                      </td>
                    </tr>
                    <tr className="border-none">
                      <td className="fw-bold">
                        {expiredOrRenew ? "Expired on: " : "Expires on:"}{" "}
                      </td>
                      <td>
                        {" "}
                        {Boolean(data?.data?.ExpiryDate) &&
                          formatDate(getExpiringDate(data?.data?.ExpiryDate))}
                      </td>
                    </tr>

                    <tr>
                      <td className="fw-bold">Current users:</td>
                      <td>{data?.data?.Num_Users}</td>
                    </tr>
                    {getStatus(data?.data?.ExpiryDate) !== "Active" ? (
                      <tr className="border-none">
                        <td className="fw-bold"> Renewer price: </td>
                        <td>
                          {data?.data?.invexVersion?.toLowerCase() ===
                            "invexerp" &&
                            /*{currency(
                              data?.data?.planDurationType === "yearly"
                                ? Number(data?.data?.coreUsers) * 250000 +
                                    Number(data?.data?.passiveUsers) * 150000
                                : data?.data?.planDurationType === "monthly"
                                ? Number(data?.data?.coreUsers) * 20850 +
                                  Number(data?.data?.passiveUsers) * 12500
                                : 0
                            ).format({
                              symbol: "",
                            })} */

                            currency(priceERPCoreUser + priceERPPassive).format(
                              {
                                symbol: "",
                              }
                            )}
                          {data?.data?.invexVersion?.toLowerCase() ===
                            "invexone" &&
                            currency(invexOnePrice).format({
                              symbol: "",
                            })}
                        </td>
                      </tr>
                    ) : (
                      <tr className="border-none">
                        <td className="fw-bold">No outstanding bill</td>
                        <td> </td>
                      </tr>
                    )}
                  </tbody>
                </Table>

                <Button
                  onClick={handleRenewPlanNavigate}
                  variant="primary"
                  className="py-3 w-100 mt-2 rounded-2"
                >
                  {getStatus(data?.data?.ExpiryDate) !== "Active"
                    ? ` Renew`
                    : "Buy more users"}
                </Button>
              </Card.Body>
            </Card>
          </div>
        </div>
      </main>
    </main>
  );
}
