import React, { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import TableComponent from "../TableComponent";
import { useAuth } from "../../hooks/useAuth";
import { useMutation, useQuery } from "react-query";
import { format, parse } from "date-fns";
import NoTableItem from "../utils/NoTableItem";
import { debounce, isEmpty } from "lodash";
import { fetchActionsUtil, formatDate } from "../../utils/helpers";
import { ErrorMessage, useFormik } from "formik";
import Datetime from "react-datetime";
import Creatable from "react-select/creatable";
import * as Yup from "yup";
import moment from "moment";
import { toast } from "react-toastify";
import NumberCustomInput from "../utils/NumberCustomInput";
import useDebounce from "../../utils/hooks";
import AsyncSelect from "react-select/async";

const maintenanceOptions = [
  {
    label: "Server ",
    value: "Server ",
  },
  {
    label: "Software Upgrade",
    value: "Software Upgrade",
  },
  {
    label: "Security Update",
    value: "Security Update",
  },
  {
    label: "Server Restart",
    value: "Server Restart",
  },
  {
    label: "Security Patch Update",
    value: "Security Patch Update",
  },
];
// Define validation schema using Yup
const validationSchema = Yup.object({
  branches: Yup.array()
    .min(1, "At least one branch is required")
    .required("Branches cannot be empty"),

  date: Yup.string().when("shecduledMaintenance", {
    is: true, // Only validate when scheduledMaintenance is true
    then: Yup.string()
      .required("Date is required")
      .test(
        "is-future-date",
        "Date cannot be in the past",
        (value) => moment(value).isSameOrAfter(moment(), "day") // Ensure it's today or later
      ),
  }),

  time: Yup.string().when("shecduledMaintenance", {
    is: true,
    then: Yup.string().required("Time is required"),
  }),
  maintenanceWindow: Yup.string()
    .required("Maintenance Window is required")
    .test(
      "greater-than-zero",
      "Invalid Maintenance Window",
      (value) => !isNaN(value) && parseFloat(value) > 0
    ),

  downTime: Yup.string()
    .required("Down Time is required")
    .test(
      "greater-than-zero",
      "Invalid Down Time",
      (value) => !isNaN(value) && parseFloat(value) > 0
    ),
  maintenanceType: Yup.string().required("Required"),
});

export default function ScheduleMaintenance({
  showCompanyBranchModal,
  companyID,
  setShowCompanyModal,
  companyName,
  refetch,
  isSpecificCompany = false,
}) {
  const { backendUrl, token, user } = useAuth();
  const [scheduling, setScheduling] = useState(false);

  const scheduleMentainance = useMutation(
    async (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/company/schedule-maintenance`,
        "POST",
        token,
        payload
      ),
    {
      onMutate: () => {
        setScheduling(true);
      },
      onSuccess: (data) => {
        if (refetch) {
          refetch();
        }

        toast.success(data.message);
        setScheduling(false);
        setShowCompanyModal(false);
      },
      onError: (error) => {
        toast.error(`Unable to perform action`, error.message);
        setScheduling(false);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      branchId: "",
      shecduledMaintenance: true,
      unscheduledMaintenance: false,
      branches: [],
      date: "",
      time: "",
      maintenanceWindow: 0,
      downTime: 0,
      user: user?.Name,
      maintainerEmail: user?.email,
      maintenanceType: "",
    },
    validationSchema,

    onSubmit: async (values) => {
      const branchData = values.branches.map((branch) => ({
        email: branch.email,
        branchId: branch.branchId,
        name: branch.name,
      }));
      const payload = {
        companyId: companyID,
        branches: branchData,
        date: values.date,
        time: values.time,
        scheduleType: values.shecduledMaintenance ? "SCHEDULED" : "UNSCHEDULED",
        maintenanceWindow: values.maintenanceWindow,
        downTime: values.downTime,
        user: user?.Name,
        maintainerEmail: user?.email,
        maintenanceType: values?.maintenanceType,
        completed: false,
      };
      scheduleMentainance.mutate(payload);
    },
  });
  const fetchCompanyBranches = async () => {
    const res = await fetch(
      `${backendUrl}/api/company/get-company-branches?companyId=${companyID}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      }
    );

    const data = await res.json();

    return data;
  };

  const { data, isFetching, isSuccess, isLoading } = useQuery(
    ["GET_COMPANY_BRANCH"],
    () => fetchCompanyBranches(),
    {
      onSuccess: (data) => {},
      enabled: true,
      keepPreviousData: false,
    }
  );
  const fetchCompanies = async (inputValue, callback) => {
    try {
      const data = await fetchActionsUtil(
        `${backendUrl}/api/company/get-company-branches?name=${inputValue}`,
        "GET"
      );

      if (data?.branches) {
        callback(
          (data.options = data?.branches?.map((el) => ({
            ...el,
            label: el.name,
            value: el.name,
          })))
        );
      }
    } catch (error) {}
  };

  const debouncedFetchUsers = debounce(fetchCompanies, 500);
  const loadOptions = (inputValue, callback) => {
    debouncedFetchUsers(inputValue, callback);
  };

  return (
    <Modal
      show={showCompanyBranchModal}
      onHide={() => setShowCompanyModal(false)}
      dialogClassName="small-modal"
      animation={false}
      enforceFocus={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h6>{companyName}</h6>
          <p className="">Fill the form to schedule maintenance</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className=" row">
            {!isSpecificCompany && (
              <Form.Group className=" mb-3 pb-2 col-md-6">
                <Form.Label className="mb-3">Select Company</Form.Label>
                <AsyncSelect
                  classNamePrefix={"form-select"}
                  className=" border"
                  placeholder="Select Company"
                  name={"branches"}
                  isSearchable={true}
                  defaultOptions
                  menuPosition="fixed"
                  loadOptions={(inputValue, callback) =>
                    loadOptions(inputValue, callback)
                  }
                  onChange={(e) => formik.setFieldValue("branches", [e])}
                />
                {formik.errors.branches && (
                  <div className="text-danger">{formik.errors.branches}</div>
                )}
              </Form.Group>
            )}

            <Form.Group className=" mb-3 pb-2 col-md-6">
              <Form.Label className="mb-3">Maintenance Type</Form.Label>

              <Creatable
                classNamePrefix={`form-select`}
                placeholder="Select or input maintenance type"
                options={maintenanceOptions}
                isSearchable
                value={maintenanceOptions?.find(
                  (el) => el.value === formik.values.label
                )}
                onChange={({ value }) => {
                  formik.setFieldValue("maintenanceType", value);
                }}
                onBlur={() => formik.setFieldTouched("maintenanceType", true)}
                inputProps={{ autoComplete: "off" }}
              />
              {formik.touched.maintenanceType &&
              !!formik.errors.maintenanceType ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.maintenanceType}
                </span>
              ) : null}
            </Form.Group>
          </div>

          {isSpecificCompany && !isEmpty(data?.branches) && (
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-3">Select Branch</Form.Label>
              {!isLoading &&
                data?.branches?.map((el, index) => (
                  <Form.Check
                    label={el?.name}
                    value={el}
                    key={index}
                    onChange={(e) => {
                      const findBranch = formik.values.branches.find(
                        (elm) => elm.id === el?.id
                      );

                      if (findBranch) {
                        formik.setFieldValue(
                          "branches",
                          formik.values.branches.filter(
                            (elm) => elm?.id !== el?.id
                          )
                        );
                      } else {
                        formik.setFieldValue("branches", [
                          ...formik.values.branches,
                          el,
                        ]);
                      }
                    }}
                  />
                ))}
              {formik.errors.branches && (
                <div className="text-danger">{formik.errors.branches}</div>
              )}
            </Form.Group>
          )}

          <div className=" row">
            <Form.Group className=" mb-3 pb-2 col-md-6">
              <Form.Check
                label={"Schedule Maintenance"}
                name="scheduleMaintenance"
                checked={formik.values.shecduledMaintenance}
                onChange={(e) => {
                  formik.setFieldValue(
                    "shecduledMaintenance",
                    e.target.checked
                  );
                  formik.setFieldValue(
                    "unscheduledMaintenance",
                    !e.target.checked
                  );
                  if (!e.target.checked) {
                    formik.setFieldValue("date", "");
                    formik.setFieldValue("time", "");
                  }
                }}
              />
            </Form.Group>
            <Form.Group className=" mb-3 pb-2 col-md-6">
              <Form.Check
                label={"Unschedule Maintenance"}
                name="unscheduledMaintenance"
                checked={formik.values.unscheduledMaintenance}
                onChange={(e) => {
                  formik.setFieldValue(
                    "unscheduledMaintenance",
                    e.target.checked
                  );

                  formik.setFieldValue(
                    "shecduledMaintenance",
                    !e.target.checked
                  );

                  if (e.target.checked) {
                    formik.setFieldValue("date", "");
                    formik.setFieldValue("time", "");
                  }
                }}
              />
            </Form.Group>
          </div>

          {formik.values.shecduledMaintenance && (
            <div className=" row">
              <Form.Group className=" mb-3 pb-2   col-md-6">
                <Form.Label className="mb-1"> Date</Form.Label>
                <Datetime
                  dateFormat="MMM DD, YYYY"
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  name="CompanyDateCreated"
                  inputProps={{
                    className: `date-input form-control  `,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.CompanyDateCreated}
                  onChange={(date) => {
                    formik.setFieldValue(
                      "date",
                      new Date(date).toISOString(),
                      true
                    );
                  }}
                  // onBlur={() => formik.setFieldTouched("CompanyDateCreated", true)}
                />
                {formik.errors.date && (
                  <div className="text-danger">{formik.errors.date}</div>
                )}
              </Form.Group>
              <Form.Group className=" mb-3 pb-2  col-md-6">
                <Form.Label className="mb-1">Time</Form.Label>
                <Datetime
                  dateFormat={false}
                  timeFormat="hh:mm A"
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  name="CompanyDateCreated"
                  inputProps={{
                    className: `date-input form-control  `,
                    placeholder: "Select time",
                    readOnly: true,
                  }}
                  value={formik.values.CompanyDateCreated}
                  onChange={(date) => {
                    formik.setFieldValue(
                      "time",
                      new Date(date).toTimeString(),
                      true
                    );
                  }}
                  // onBlur={() => formik.setFieldTouched("CompanyDateCreated", true)}
                />
                {formik.errors.time && (
                  <div className="text-danger">{formik.errors.time}</div>
                )}
              </Form.Group>
            </div>
          )}

          <div className="row">
            <Form.Group className=" mb-3 pb-2  col-md-6">
              <Form.Label className="mb-1">
                Maintenance Window (hour)
              </Form.Label>
              <NumberCustomInput
                type="text"
                placeholder="Enter Maintenance Window"
                name="maintenanceWindow"
                value={formik.values.maintenanceWindow}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                // isInvalid={formik.touched.Pieces && !!formik.errors.Pieces}
                //   disabled
              />
              {formik.errors.maintenanceWindow && (
                <div className="text-danger">
                  {formik.errors.maintenanceWindow}
                </div>
              )}
            </Form.Group>
            <Form.Group className=" mb-3 pb-2  col-md-6">
              <Form.Label className="mb-1">Down Time (hour)</Form.Label>
              <NumberCustomInput
                type="text"
                placeholder="Enter Server Down Time"
                name="downTime"
                value={formik.values.downTime}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                // isInvalid={formik.touched.Pieces && !!formik.errors.Pieces}
                //   disabled
              />
              {formik.errors.downTime && (
                <div className="text-danger">{formik.errors.downTime}</div>
              )}
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" type="submit" onClick={formik.handleSubmit}>
          {scheduling ? "Scheduling" : "Schedule"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
