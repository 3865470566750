import React, { useRef, useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import undo from "../../assets/icons/undo.svg";
// import { IoMdUndo } from "react-icons/io";

const SignaturePad = ({
  signatureCanvas,
  strokes,
  setStrokes,
  setSignature,
  signature,
}) => {
  // Function to save a stroke and add it to the strokes array
  const handleEndStroke = () => {
    const currentDataURL = signatureCanvas.current.toDataURL();
    setStrokes((prevStrokes) => [...prevStrokes, currentDataURL]); // Save the canvas state after each stroke
  };
  const sigCanvas = useRef(strokes);


  // Function to clear the signature pad
  const clearSignature = () => {
    signatureCanvas.current.clear();
    setStrokes([]); // Clear all tracked strokes
  };

  // Undo the last stroke
  const undoLastStroke = () => {
    if (strokes.length > 0) {
      const newStrokes = strokes.slice(0, -1); // Remove the last stroke
      setStrokes(newStrokes);

      // Clear the canvas and redraw from saved strokes
      signatureCanvas.current.clear();
      newStrokes.forEach((stroke) => {
        const img = new Image();
        img.src = stroke;
        img.onload = () => {
          signatureCanvas.current
            .getCanvas()
            .getContext("2d")
            .drawImage(img, 0, 0);
        };
      });
    }
  };

  return (
    <div className="signature">
      <div className="container">
        {signature ? (
          <>
            <img
              src={signature}
              alt="User Signature"
              style={{
                borderRadius: "5px",
                width: "300px",
                height: "180px",
              }}
            />
            <div className="buttons create">
              <button onClick={() => setSignature(null)}>
                Create new signature
              </button>
            </div>
          </>
        ) : (
          <>
            <SignatureCanvas
              ref={signatureCanvas}
              penColor="black"
              onEnd={handleEndStroke} // Triggered when the user completes a stroke
              canvasProps={{
                className: "signatureCanvas",
                height: "180px",
              }}
            />
            <div className="buttons">
              <a onClick={clearSignature}>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 24 24"
                  height="200px"
                  width="200px"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
              </a>
              {/* <IoMdUndo onClick={undoLastStroke} disabled={strokes.length === 0} /> */}
              <a onClick={undoLastStroke} disabled={strokes.length === 0}>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="200px"
                  width="200px"
                >
                  <path d="M262.3 179.6c-57.9 0-110.3 21.6-150.7 56.8L32 160v192h197.5l-79.1-76.8c30.4-25.3 69-41.1 111.8-41.1 77.3 0 143.1 48.2 166 117.8l51.8-14.8c-30.4-91.4-116.2-157.5-217.7-157.5z" />
                </svg>
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SignaturePad;

