import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Modal, Form, Nav, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import * as yup from "yup";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import EyeOutline from "mdi-react/EyeOutlineIcon";

import { useBackendUrl, useEffectOnce } from "../utils/hooks";
import Avatar from "./utils/Avatar";
import { useAuth } from "../hooks/useAuth";
import Cookie from "universal-cookie";

export default function VerifyEmailModal({
  show,
  onHide,
  email,
  name,
  showUpdateEmailModal,
}) {
  const cookies = new Cookie();
  const { user: authUser, setUser } = useAuth();
  const [activeKey, setActiveKey] = useState("password");
  const [showText, setShowText] = useState({
    password: false,
    confirmpassword: false,
    oldPassword: false,
  });

  const backendUrl = useBackendUrl();
  // const token = useToken();

  const [isLoading, setIsLoading] = useState(false);

  const updateAccount = async (values) => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `${backendUrl}/api/auth/send-staff-verify-mail`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(values),
        }
      );

      let requestResponse = await response.json();

      if (requestResponse.success == false) {
        toast.error(requestResponse.message);
      } else {
        // onHide(false);
        toast.success(`A verification email has been sent to ${values.email}`);
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to submit");
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: authUser.email,
      Staff_ID: authUser.Staff_ID,
      companyName: authUser?.company,
    },
    validationSchema: yup.object().shape({
      email: yup.string().required(),
    }),
    onSubmit: (values) => {
      updateAccount(values);
    },
  });

  // ---------------------------

  //-----------------------------

  useEffectOnce(() => {
    setUp();
  });

  const setUp = () => {
    formik.setFieldValue("email", authUser.email);
  };

  return (
    <>
      <Modal
        size="md"
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Verify Email{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="row">
          <div className="col px-4">
            <Form className="col" noValidate onSubmit={formik.handleSubmit}>
              <span>Hi, {name}</span>
              <span>
                {" "}
                is <strong>{email}</strong> still your email address?
              </span>
              <div>
                <Button
                  disabled={isLoading}
                  variant="primary"
                  className="my-3"
                  // onClick={formik.handleSubmit}
                  type="submit"
                >
                  {isLoading ? "Please wait…" : "Yes, verify"}
                </Button>
                &nbsp;&nbsp;
                <Button
                  disabled={isLoading}
                  variant="block"
                  className="my-3"
                  onClick={showUpdateEmailModal}
                  type="button"
                >
                  No, update email
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            disabled={isLoading}
            variant="primary"
            className=""
            type="submit"
          >
            {isLoading ? "Please wait…" : "Continue"}
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}
