import currency from "currency.js";
import { formatForQty } from "../../utils/helpers";
import {
  BucketIcon,
  InvoiceDashboardIcon,
  InvoiceOverDue,
  InvoicePaidIcon,
  InvoiceUpcomingIcon,
  InvoiceUpnaidIcon,
  LineChartIcon,
  OverdueInvoiceStatsIcon,
  PaidInvoiceStatIcon,
  SideBarToggleIcon,
  UnpaidInvoiceStatsIcon,
  UpcomingInvoiceStatsIcon,
} from "../Icons";
import { Link, Outlet } from "react-router-dom";
import "../../assets/scss/customInvoiceWrapper.scss";
import InvoiceAnalysisChart from "./InvoiceAnalysisChart";
import InvoiceAnalysisPieChart from "./InvoiceAnalysisPieChart";
import { useState } from "react";
import { Button } from "react-bootstrap";
const items = [
  { name: "Dashboard", icon: <InvoiceDashboardIcon />, link: "" },
  { name: "Paid", icon: <InvoicePaidIcon />, link: "/invoices/paid-invoices" },
  {
    name: "Unpaid",
    icon: <InvoiceUpnaidIcon />,
    link: "/invoices/unpaid-invoices",
  },
  {
    name: "Uncoming",
    icon: <InvoiceUpcomingIcon />,
    link: "/invoices/upcoming-invoices",
  },
  {
    name: "Overdue",
    icon: <InvoiceOverDue />,
    link: "/invoices/overdue-invoices",
  },
];
export default function InvoiceLayout({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="custom-invoice-wrapper ">
        <div className={"d-flex"}>
          <div className={isOpen ? "dashboard-menu" : " dashboard-menu-close"}>
            <div className="">
              <ul>
                {items.map((item, index) => (
                  <Link
                    to={item.link}
                    className="btn d-flex    align-items-center gap-1 custom-link"
                    title={!isOpen ? item?.name : ""}
                  >
                    <div className="icon">{item.icon}</div>
                    {isOpen && <span>{item.name}</span>}
                  </Link>
                ))}
                <Button onClick={() => setIsOpen((prev) => !prev)}>
                  <SideBarToggleIcon />
                </Button>
              </ul>
            </div>
          </div>

          <div className="custom-invoice-stats">
            <section className=" custom-stat-items">
              <section className={`custom-stats mb-3  `}>
                <div className="custom-stat  border rounded-4">
                  <div className="icon icon-1">
                    <PaidInvoiceStatIcon />
                  </div>
                  <div className="custom-details">
                    <p>
                      {currency(0, {
                        symbol: "",
                        precision: 2,
                        format: formatForQty,
                      }).format()}
                    </p>
                    <span>Total Inventory</span>
                  </div>
                </div>

                <div className="custom-stat  border rounded-4">
                  <div className="icon bg-light-blue">
                    <UpcomingInvoiceStatsIcon />
                  </div>
                  <div className="custom-details">
                    <p>
                      {currency(0, {
                        symbol: "",
                        precision: 2,
                        format: formatForQty,
                      }).format()}
                    </p>
                    <span>Total Inventory</span>
                  </div>
                </div>

                <div className="custom-stat  border rounded-4">
                  <div className="icon ">
                    <OverdueInvoiceStatsIcon />
                  </div>
                  <div className="custom-details">
                    <p>
                      {currency(0, {
                        symbol: "",
                        precision: 2,
                        format: formatForQty,
                      }).format()}
                    </p>
                    <span>Total Inventory</span>
                  </div>
                </div>

                <div className="custom-stat  border rounded-4">
                  <div className="icon  icon-2">
                    <UnpaidInvoiceStatsIcon />
                  </div>
                  <div className="custom-details">
                    <p>
                      {currency(0, {
                        symbol: "",
                        precision: 2,
                        format: formatForQty,
                      }).format()}
                    </p>
                    <span>Total Inventory</span>
                  </div>
                </div>
              </section>

              {children}
            </section>
          </div>
        </div>

        {/* {Nav && <div className="nav-bar">{Nav}</div>} */}
        <Outlet />
      </div>
    </>
  );
}
