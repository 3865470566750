import React, { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import TableComponent from "../TableComponent";
import { useAuth } from "../../hooks/useAuth";
import { useMutation, useQuery } from "react-query";
import { format, parse } from "date-fns";
import NoTableItem from "../utils/NoTableItem";
import { debounce, isEmpty } from "lodash";
import { fetchActionsUtil, formatDate } from "../../utils/helpers";
import { ErrorMessage, useFormik } from "formik";
import Datetime from "react-datetime";
import Creatable from "react-select/creatable";
import * as Yup from "yup";
import moment from "moment";
import { toast } from "react-toastify";
import NumberCustomInput from "../utils/NumberCustomInput";
import useDebounce from "../../utils/hooks";
import AsyncSelect from "react-select/async";
import Select from "react-select";

const options = [
  { label: "InvexOne", value: "InvexOne" },
  { label: "InvexERP", value: "InvexERP" },
];
const planOption = [
  { label: "monthly", value: "monthly" },
  { label: "yearly", value: "yearly" },
];

const validationSchema = Yup.object({
  company: Yup.string().required("required"),
  invexVersion: Yup.string().required("required"),
  numberOfUsers: Yup.string().required("required"),
  ExpiryDate: Yup.string().required("required"),
  planDurationType: Yup.string().required("required"),

  invexOnePricePerYear: Yup.string().when("invexVersion", {
    is: "InvexOne",
    then: Yup.string().required("required"),
  }),
  invexOnePricePerMonth: Yup.string().when("invexVersion", {
    is: "InvexOne",
    then: Yup.string().required("required"),
  }),
  numberOfCoreUsers: Yup.string().when("invexVersion", {
    is: "InvexERP",
    then: Yup.string().required("required"),
  }),
  numberOfPassiveUsers: Yup.string().when("invexVersion", {
    is: "InvexERP",
    then: Yup.string().required("required"),
  }),
  coreUserPerYear: Yup.string().when("invexVersion", {
    is: "InvexERP",
    then: Yup.string().required("required"),
  }),
  passiveUserPerYear: Yup.string().when("invexVersion", {
    is: "InvexERP",
    then: Yup.string().required("required"),
  }),
  coreUserPerMonth: Yup.string().when("invexVersion", {
    is: "InvexERP",
    then: Yup.string().required("required"),
  }),
  passiveUserPerMonth: Yup.string().when("invexVersion", {
    is: "InvexERP",
    then: Yup.string().required("required"),
  }),
});

export default function PrincingModal({
  open,
  close,

  refetch,
}) {
  const { backendUrl, token, user } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setupPricing = useMutation(
    async (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/company/eb-setup-pricing`,
        "POST",
        token,
        payload
      ),
    {
      onMutate: () => {
        setIsSubmitting(true);
      },
      onSuccess: ({ data }) => {
        if (refetch) {
          refetch();
        }

        toast.success(data.message);
        setIsSubmitting(false);
        close(false);
      },
      onError: (error) => {
        toast.error(`Unable to perform action`, error.message);
        setIsSubmitting(false);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      company: "",
      invexVersion: "InvexERP",
      numberOfUsers: "",
      invexOnePricePerYear: "",
      invexOnePricePerMonth: "",
      numberOfCoreUsers: "",
      numberOfPassiveUsers: "",
      coreUserPerYear: "",
      passiveUserPerYear: "",
      coreUserPerMonth: "",
      passiveUserPerMonth: "",
      ExpiryDate: "",
      companyID: "",
      planDurationType: "",
    },
    validationSchema,

    onSubmit: async (values) => {
      setupPricing.mutate(values);
    },
  });

  ///h:mm aa
  const fetchCompanyPricing = async (query) => {
    const res = await fetch(
      `${backendUrl}/api/company/get-other-company-pricing?companyID=${query}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      }
    );

    const data = await res.json();

    return data;
  };

  const { data, isFetching, isSuccess } = useQuery(
    ["GET_EB_COMPANY", formik.values.company],
    () =>
      Boolean(formik.values.company) &&
      fetchCompanyPricing(formik.values.company),
    {
      enabled: Boolean(formik.values.company),
      keepPreviousData: true,
      onSuccess: ({ data }) => {
        console.log("====================================");
        console.log(data);
        console.log("====================================");
        formik.setFieldValue("invexVersion", data?.invexVersion || "InvexERP");
        formik.setFieldValue("coreUserPerMonth", data?.coreUserPeryMonth || "");
        formik.setFieldValue(
          "passiveUserPerMonth",
          data?.passiveUserPeryMonth || ""
        );
        formik.setFieldValue(
          "passiveUserPerYear",
          data?.passiveUserPerYear || ""
        );
        formik.setFieldValue("coreUserPerYear", data?.coreUserPerYear || "");
        formik.setFieldValue(
          "invexOnePricePerMonth",
          data?.invexOnePricePerMonth || ""
        );
        formik.setFieldValue(
          "invexOnePricePerYear",
          data?.invexOnePricePerYear || ""
        );
        formik.setFieldValue("planDurationType", data?.planDurationType || "");
      },
    }
  );

  const fetchCompanies = async (inputValue, callback) => {
    try {
      const data = await fetchActionsUtil(
        `${backendUrl}/api/company/get-company-branches?name=${inputValue}`,
        "GET"
      );

      if (data?.branches) {
        callback(
          (data.options = data?.branches?.map((el) => ({
            ...el,
            label: el.name,
            value: el.name,
          })))
        );
      }
    } catch (error) {}
  };

  const debouncedFetchUsers = debounce(fetchCompanies, 500);
  const loadOptions = (inputValue, callback) => {
    debouncedFetchUsers(inputValue, callback);
  };

  return (
    <Modal
      show={open}
      onHide={() => close(false)}
      dialogClassName="small-modal"
      animation={false}
      enforceFocus={false}
      centered
      style={{
        pointerEvents: isFetching ? "none" : "",
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h6>Setup Company Pricing</h6>
          <p className="">Fill the form to setup company price</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="">Select Company</Form.Label>
              <AsyncSelect
                classNamePrefix={"form-select"}
                className=" border"
                placeholder="Select Company"
                name={"branches"}
                isSearchable={true}
                defaultOptions
                menuPosition="fixed"
                loadOptions={(inputValue, callback) =>
                  loadOptions(inputValue, callback)
                }
                onChange={(e) => {
                  formik.setFieldValue("numberOfUsers", e.totalUsers || 0);
                  if (formik.values?.invexVersion === "InvexERP") {
                    formik.setFieldValue("numberOfCoreUsers", e.coreUsers || 0);
                    formik.setFieldValue(
                      "numberOfPassiveUsers",
                      e.passiveUsers || 0
                    );
                  } else {
                    formik.setFieldValue("numberOfCoreUsers", 0);
                    formik.setFieldValue("numberOfPassiveUsers", 0);
                  }

                  formik.setFieldValue("company", e?.BackendUrl);
                  formik.setFieldValue(
                    "ExpiryDate",
                    new Date(e?.CompanyExpiringDate)
                  );
                }}
              />
              {formik.errors.company && formik.touched.company && (
                <div className="text-danger">{formik.errors.company}</div>
              )}
            </Form.Group>
          </div>
          <div className=" row">
            <Form.Group className="mb-3 pb-2 col-md-6">
              <Form.Label>Total Number of Users</Form.Label>
              <NumberCustomInput
                type="text"
                placeholder="0"
                name="numberOfUsers"
                value={formik.values.numberOfUsers}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                // isInvalid={formik.touched.Pieces && !!formik.errors.Pieces}
                //   disabled
              />
              {formik.errors.numberOfUsers && formik.touched.numberOfUsers && (
                <div className="text-danger">{formik.errors.numberOfUsers}</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3 pb-2 col-md-6">
              <Form.Label>Select Invex Version</Form.Label>
              <Select
                classNamePrefix="form-select"
                menuPosition="fixed"
                menuPlacement="auto"
                placeholder="Select invex version"
                name="invexVersion"
                key={options}
                onChange={(selected) => {
                  formik.setFieldValue("invexVersion", selected?.value);
                }}
                options={options || []}
                value={options?.find(
                  (option) => option.value === formik?.values?.invexVersion
                )}
              />
              {formik.errors.invexVersion && formik.touched.invexVersion && (
                <div className="text-danger">{formik.errors.invexVersion}</div>
              )}
            </Form.Group>
          </div>

          {formik.values.invexVersion === "InvexOne" && (
            <div className=" row">
              <Form.Group className="mb-3 pb-2 col-md-6">
                <Form.Label>Price/User/Year</Form.Label>
                <NumberCustomInput
                  type="text"
                  placeholder="0"
                  name="invexOnePricePerYear"
                  value={formik.values.invexOnePricePerYear}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  // isInvalid={formik.touched.Pieces && !!formik.errors.Pieces}
                  //   disabled
                />
                {formik.errors.invexOnePricePerYear &&
                  formik.touched.invexOnePricePerYear && (
                    <div className="text-danger">
                      {formik.errors.invexOnePricePerYear}
                    </div>
                  )}
              </Form.Group>
              <Form.Group className="mb-3 pb-2 col-md-6">
                <Form.Label>Price/User/Month</Form.Label>
                <NumberCustomInput
                  type="text"
                  placeholder="0"
                  name="invexOnePricePerMonth"
                  value={formik.values.invexOnePricePerMonth}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  // isInvalid={formik.touched.Pieces && !!formik.errors.Pieces}
                  //   disabled
                />
                {formik.errors.invexOnePricePerMonth &&
                  formik.touched.invexOnePricePerMonth && (
                    <div className="text-danger">
                      {formik.errors.invexOnePricePerMonth}
                    </div>
                  )}
              </Form.Group>
            </div>
          )}

          {formik.values?.invexVersion === "InvexERP" && (
            <div className="row">
              <Form.Group className="mb-3 pb-2 col-md-6">
                <Form.Label>Number of Core Users</Form.Label>
                <NumberCustomInput
                  type="text"
                  placeholder="0"
                  name="numberOfCoreUsers"
                  value={formik.values.numberOfCoreUsers}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                    if (
                      isNaN(value) ||
                      isNaN(formik.values.numberOfPassiveUsers)
                    )
                      return;

                    formik.setFieldValue(
                      "numberOfUsers",
                      Number(value) + Number(formik.values.numberOfPassiveUsers)
                    );
                  }}
                  // isInvalid={formik.touched.Pieces && !!formik.errors.Pieces}
                  //   disabled
                />
                {formik.errors.numberOfCoreUsers &&
                  formik.touched.numberOfCoreUsers && (
                    <div className="text-danger">
                      {formik.errors.numberOfCoreUsers}
                    </div>
                  )}
              </Form.Group>
              <Form.Group className="mb-3 pb-2 col-md-6">
                <Form.Label>Number of Passive Users</Form.Label>
                <NumberCustomInput
                  type="text"
                  placeholder="0"
                  name="numberOfPassiveUsers"
                  value={formik.values.numberOfPassiveUsers}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                    if (isNaN(value) || isNaN(formik.values.numberOfCoreUsers))
                      return;

                    formik.setFieldValue(
                      "numberOfUsers",
                      Number(value) + Number(formik.values.numberOfCoreUsers)
                    );
                  }}
                  // isInvalid={formik.touched.Pieces && !!formik.errors.Pieces}
                  //   disabled
                />
                {formik.errors.numberOfPassiveUsers &&
                  formik.touched.numberOfPassiveUsers && (
                    <div className="text-danger">
                      {formik.errors.numberOfPassiveUsers}
                    </div>
                  )}
              </Form.Group>
              <Form.Group className="mb-3 pb-2 col-md-6">
                <Form.Label>Price/Core User/Year</Form.Label>
                <NumberCustomInput
                  type="text"
                  placeholder="0"
                  name="coreUserPerYear"
                  value={formik.values.coreUserPerYear}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  // isInvalid={formik.touched.Pieces && !!formik.errors.Pieces}
                  //   disabled
                />
                {formik.errors.coreUserPerYear &&
                  formik.touched.coreUserPerYear && (
                    <div className="text-danger">
                      {formik.errors.coreUserPerYear}
                    </div>
                  )}
              </Form.Group>
              <Form.Group className="mb-3 pb-2 col-md-6">
                <Form.Label>Price/Passive User/Year</Form.Label>
                <NumberCustomInput
                  type="text"
                  placeholder="0"
                  name="passiveUserPerYear"
                  value={formik.values.passiveUserPerYear}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  // isInvalid={formik.touched.Pieces && !!formik.errors.Pieces}
                  //   disabled
                />
                {formik.errors.passiveUserPerYear &&
                  formik.touched.passiveUserPerYear && (
                    <div className="text-danger">
                      {formik.errors.passiveUserPerYear}
                    </div>
                  )}
              </Form.Group>
              <Form.Group className="mb-3 pb-2 col-md-6">
                <Form.Label>Price/Core User/Month</Form.Label>
                <NumberCustomInput
                  type="text"
                  placeholder="0"
                  name="coreUserPerMonth"
                  value={formik.values.coreUserPerMonth}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  // isInvalid={formik.touched.Pieces && !!formik.errors.Pieces}
                  //   disabled
                />
                {formik.errors.coreUserPerMonth &&
                  formik.touched.coreUserPerMonth && (
                    <div className="text-danger">
                      {formik.errors.coreUserPerMonth}
                    </div>
                  )}
              </Form.Group>
              <Form.Group className="mb-3 pb-2 col-md-6">
                <Form.Label>Price/Passive User/Month</Form.Label>
                <NumberCustomInput
                  type="text"
                  placeholder="0"
                  name="passiveUserPerMonth"
                  value={formik.values.passiveUserPerMonth}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  // isInvalid={formik.touched.Pieces && !!formik.errors.Pieces}
                  //   disabled
                />
                {formik.errors.passiveUserPerMonth &&
                  formik.touched.passiveUserPerMonth && (
                    <div className="text-danger">
                      {formik.errors.passiveUserPerMonth}
                    </div>
                  )}
              </Form.Group>
            </div>
          )}

          <div className=" row">
            <Form.Group className=" mb-3 pb-2   col-md-6">
              <Form.Label className="mb-1"> Expiry Date</Form.Label>
              <Datetime
                dateFormat="MMM DD, YYYY "
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside={true}
                name="ExpiryDate"
                inputProps={{
                  className: `date-input form-control  `,
                  placeholder: "Select date",
                  readOnly: true,
                }}
                value={formik.values.ExpiryDate}
                onChange={(date) => {
                  formik.setFieldValue(
                    "ExpiryDate",
                    new Date(date).toISOString(),
                    true
                  );
                }}
                // onBlur={() => formik.setFieldTouched("CompanyDateCreated", true)}
              />
              {formik.errors.ExpiryDate && (
                <div className="text-danger">{formik.errors.ExpiryDate}</div>
              )}
            </Form.Group>

            <Form.Group className="mb-3 pb-2 col-md-6">
              <Form.Label>Select Plan Type</Form.Label>
              <Select
                classNamePrefix="form-select"
                menuPosition="fixed"
                menuPlacement="auto"
                placeholder="Select plan "
                name="planDurationType"
                key={planOption}
                onChange={(selected) => {
                  formik.setFieldValue("planDurationType", selected?.value);
                }}
                options={planOption || []}
                value={planOption?.find(
                  (option) => option.value === formik?.values?.planDurationType
                )}
              />
              {formik.errors.planDurationType &&
                formik.touched.planDurationType && (
                  <div className="text-danger">
                    {formik.errors.planDurationType}
                  </div>
                )}
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" type="submit" onClick={formik.handleSubmit}>
          {isSubmitting ? "Saving..." : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
